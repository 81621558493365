import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  MenuItem,
  TextField,
  CircularProgress,
  Snackbar,
  Autocomplete,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
} from "@mui/material";
import i18n from "i18next";
import { Add, Remove } from "@mui/icons-material";
import { useTranslation, initReactI18next } from "react-i18next";
import translationEN from "../locales/en/translation.json"; // Adjust the path as needed
import translationAR from "../locales/ar/translation.json"; // Adjust the path as neededimport { API_ENDPOINTS } from '../api/apiConfig';
import { API_ENDPOINTS } from "../api/apiConfig";
// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    ar: { translation: translationAR },
  },
  lng: "ar", // Default language
  fallbacklng: "ar",
  interpolation: { escapeValue: false },
});
const ProductListScreen = () => {
  const { t } = useTranslation();

  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([{ id: "", type: t("allTypes") }]);
  const [saleFors, setSaleFors] = useState([
    { id: "", saleFor: t("allPrices") },
  ]);
  const [loadData, setLoadData] = useState(true);
  const [selectedType, setSelectedType] = useState("");
  const [selectedSaleFor, setSelectedSaleFor] = useState("");
  const [productCounts, setProductCounts] = useState({});
  const [orders, setOrders] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchTypes();
    fetchSaleFors();
    fetchProducts();
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoadData(true);

    try {
      const response = await fetch(API_ENDPOINTS.getAllUser, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
      });

      if (!response.ok) {
        throw new Error("Failed to load products");
      }

      const products = await response.json();
      setUsers(products);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadData(false);
    }
  };

  const fetchProducts = async (type, saleFor) => {
    setLoadData(true);
    const filters = {};
    if (type) {
      filters.type = type;
    }
    if (saleFor) {
      filters.saleFor = saleFor;
    }
    filters.KEY = process.env.REACT_APP_API_KEY;
    try {
      const response = await fetch(API_ENDPOINTS.getProductsNoActiv, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        throw new Error("Failed to load products");
      }

      const data = await response.json();
      const products = data.map((product) => ({
        ...product,
        imageUrl: product.imageUrl.replace("localhost", "192.168.1.2"),
      }));

      setProducts(products);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadData(false);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.getTypes, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
      });

      if (!response.ok) {
        throw new Error("Failed to load types");
      }

      const data = await response.json();
      setTypes((prevTypes) => [...prevTypes, ...data]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSaleFors = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.getSaleFor, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
      });

      if (!response.ok) {
        throw new Error("Failed to load saleFors");
      }

      const data = await response.json();
      setSaleFors((prevSaleFors) => [...prevSaleFors, ...data]);
    } catch (error) {
      console.error(error);
    }
  };

  const incrementCount = (productId) => {
    setProductCounts((prevCounts) => {
      const newCounts = {
        ...prevCounts,
        [productId]: (prevCounts[productId] || 0) + 1,
      };
      updateOrders(productId, newCounts[productId]);
      return newCounts;
    });
  };

  const decrementCount = (productId) => {
    setProductCounts((prevCounts) => {
      const newCounts = {
        ...prevCounts,
        [productId]: Math.max((prevCounts[productId] || 1) - 1, 0),
      };
      updateOrders(productId, newCounts[productId]);
      return newCounts;
    });
  };

  const updateOrders = (productId, count) => {
    setOrders((prevOrders) => {
      const newOrders = { ...prevOrders };
      const product = products.find((p) => p.id === productId);
      if (count > 0) {
        newOrders[productId] = { ...product, count };
        setSnackbarMessage(t("orderUpdated"));
      } else {
        delete newOrders[productId];
        setSnackbarMessage(t("orderRemoved"));
      }
      setSnackbarOpen(true);
      return newOrders;
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (event, value) => {
    setSelectedOption(value);
  };

  const saveOrders = async () => {
    if (!selectedOption) {
      setSnackbarMessage(t("pleaseSelectUser"));
      setSnackbarOpen(true);
      return;
    }

    var newOrders = Object.values(orders);
    var index = 0;
    for (var ord in newOrders) {
      if (newOrders[index]["discount"] == true) {
        newOrders[index]["price"] = newOrders[index]["discountPrice"];
      }
      index++;
    }
    //console.log(newOrders);

    try {
      const response = await fetch(API_ENDPOINTS.saveOrder, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          idUser: selectedOption.id,
          orders: newOrders,
          KEY: process.env.REACT_APP_API_KEY,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save orders");
      }

      const data = await response.json();
      setSnackbarMessage(data.message || t("ordersSaved"));
    } catch (error) {
      setSnackbarMessage(t("failedToSaveOrders"));
      console.error(error);
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.name}
        onChange={handleChange}
        value={selectedOption}
        renderInput={(params) => (
          <TextField {...params} label={t("selectOption")} variant="outlined" />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <ListItemAvatar>
              <Avatar src={option.imageUrl} alt={option.name} />
            </ListItemAvatar>
            <ListItemText primary={option.name} />
          </ListItem>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <Container>
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12}>
            <TextField
              select
              label={t("type")}
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value);
                fetchProducts(e.target.value, selectedSaleFor);
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label={t("saleFor")}
              value={selectedSaleFor}
              onChange={(e) => {
                setSelectedSaleFor(e.target.value);
                fetchProducts(selectedType, e.target.value);
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {saleFors.map((saleFor) => (
                <MenuItem key={saleFor.id} value={saleFor.id}>
                  {saleFor.saleFor}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {loadData ? (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            products.map((product) => {
              const productId = product.id;
              const count = productCounts[productId] || 0;

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={productId}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt={product.name}
                      height="140"
                      image={product.imageUrl}
                      title={product.name}
                    />
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {product.name}
                      </Typography>
                      <Typography color="textSecondary">
                        {t("price")}: {product.price}
                      </Typography>
                      {product.discount && (
                        <Typography variant="body2" color="error">
                          {t("discountedPrice")}: {product.discountPrice}
                        </Typography>
                      )}
                      <Typography color="textSecondary">
                        {t("amount")}: {product.amount}
                      </Typography>
                      <Typography color="textSecondary">
                        {t("saleForValue")}: {product.saleFor}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => decrementCount(productId)}
                          disabled={count <= 0}
                        >
                          <Remove />
                        </IconButton>
                        <Typography>{count}</Typography>
                        <IconButton onClick={() => incrementCount(productId)}>
                          <Add />
                        </IconButton>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={saveOrders}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          {t("saveOrders")}
        </Button>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ProductListScreen;
