import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { blobToURL, fromBlob } from "image-resize-compress";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { API_ENDPOINTS } from "../api/apiConfig";

function AddProduct() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");
  const [saleFor, setSaleFor] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [types, setTypes] = useState([]);
  const [saleForlist, setSaleForlist] = useState([]);
  const [discount, setDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState("");
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth();
  const { t, i18n } = useTranslation();
  const [searchType, setSearchType] = useState([]);

  const getDataSaleFor = (url) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("data");
        //console.log(data);
        setSaleForlist(data);
      })
      .catch((error) => {
        console.error("Error fetching SaleFors:", error);
      });
  };

  const getDataTypes = (url) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("data");
        //console.log(data);
        setTypes(data);
      })
      .catch((error) => console.error("Error fetching SaleFors:", error));
  };

  useEffect(() => {
    getDataTypes(API_ENDPOINTS.getTypes);
    getDataSaleFor(API_ENDPOINTS.getSaleFor);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const quality = 80;
      const width = 0;
      const height = 0;
      const format = "webp";
      fromBlob(file, quality, width, height, format).then((blob) => {
        //console.log(blob);
        setImage(blob);
        blobToURL(blob).then((url) => {
          //console.log("url")
          //console.log(url)
          setImagePreviewUrl(url);
        });
      });
    } else {
      setImage(null);
      setImagePreviewUrl("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("email", currentUser.email);
    formData.append("image", image);
    formData.append("KEY", process.env.REACT_APP_API_KEY);

    try {
      const response = await fetch(API_ENDPOINTS.imageChange, {
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      if (response.ok) {
        fetch(API_ENDPOINTS.createProduct, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            description: description,
            imageUrl: responseData.imageUrl,
            price: parseFloat(price),
            amount: parseInt(amount),
            types: searchType,
            saleFor: saleFor,
            idUsers: userData.id,
            isActive: isActive,
            sortInList: 1,
            discount: discount,
            discountPrice: discount ? parseFloat(discountPrice) : null,
            KEY: process.env.REACT_APP_API_KEY,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            navigate("/");
          })
          .catch((error) => console.error("Error submitting form:", error));
      } else {
        throw new Error(responseData.message || "Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setError(t("error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ mt: 4, mx: "auto", width: "90%", maxWidth: 500 }}
    >
      <Typography variant="h6">{t("addProduct")}</Typography>
      <Button variant="contained" component="label" fullWidth sx={{ mt: 2 }}>
        {t("uploadImage")}
        <input type="file" hidden onChange={handleImageChange} required />
      </Button>
      {imagePreviewUrl && (
        <Box mt={2} display="flex" justifyContent="center">
          <img
            src={imagePreviewUrl}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: 150 }}
          />
        </Box>
      )}
      <TextField
        label={t("name")}
        fullWidth
        margin="normal"
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label={t("description")}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        label={t("price")}
        fullWidth
        margin="normal"
        type="number"
        required
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <TextField
        label={t("amount")}
        fullWidth
        margin="normal"
        type="number"
        required
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <FormControl fullWidth variant="outlined" margin="normal">
        <Autocomplete
          multiple
          options={types.map((type) => type.type)}
          value={searchType}
          onChange={(event, newValue) => setSearchType(newValue)}
          renderInput={(params) => (
            <TextField {...params} label={t("type")} variant="outlined" />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>{t("saleFor")}</InputLabel>
        <Select
          value={saleFor}
          label={t("saleFor")}
          required
          onChange={(e) => setSaleFor(e.target.value)}
        >
          {saleForlist?.map((sale) => (
            <MenuItem key={sale.id} value={sale.saleFor}>
              {sale.saleFor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={discount}
            onChange={(e) => setDiscount(e.target.checked)}
            color="primary"
          />
        }
        label={t("discount")}
        sx={{ mt: 2 }}
      />
      {discount && (
        <TextField
          label={t("discountPrice")}
          fullWidth
          margin="normal"
          type="number"
          required
          value={discountPrice}
          onChange={(e) => setDiscountPrice(e.target.value)}
        />
      )}
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            color="primary"
          />
        }
        label={t("isActive")}
        sx={{ mt: 2 }}
      />

      {error && <Typography color="error">{error}</Typography>}
      {loading ? (
        <CircularProgress sx={{ display: "block", mx: "auto", my: 2 }} />
      ) : (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          {t("addProductButton")}
        </Button>
      )}
    </Box>
  );
}

export default AddProduct;
